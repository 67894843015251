import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingContactComponent } from './components/booking-contact/booking-contact.component';
import { BookingSuccessComponent } from './components/booking-success/booking-success.component';
import { OutstationComponent } from './components/outstation/outstation.component';
import { AboutComponent } from './pages/about/about.component';
import { AllRoutesComponent } from './pages/all-routes/all-routes.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';
import { CityListComponent } from './pages/city-list/city-list.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { TariffComponent } from './pages/tariff/tariff.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'select-cabs',
    component: OutstationComponent
  },
  {
    path: 'booking',
    component: BookingContactComponent
  },
  {
    path: 'success-booking',
    component: BookingSuccessComponent
  },
  {
    path: 'city',
    component: AllRoutesComponent
  },
  {
    path: 'city/:cityName',
    component: CityListComponent
  },
  {
    path: 'city-detail/:url',
    component: CityDetailComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'tariff',
    component: TariffComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
