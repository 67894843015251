<footer class="pt-20 pb-4" style="background-image: url(assets/images/background_pattern.png);">
    <div class="section-shape top-0" style="background-image: url(assets/images/shape8.png);"></div>
    <div class="footer-upper pb-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4 pe-4">
                    <div class="footer-about">
                        <img src="assets/logo-white.png" alt="">
                        <p class="mt-3 mb-3 white">
                            {{settings.siteName}} is available all over the southern states. You can save up to 40
                            percent
                            taxi fare compare to others Presenting in the most prominent cities in India.


                        </p>
                        <ul>
                            <li class="white"><strong>Phone No</strong> +91-{{settings.phoneNo}}</li>
                            <li class="white"><strong>Location:</strong> {{settings.address}}</li>
                            <li class="white"><strong>Email:</strong> <a
                                    href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a>
                            </li>
                            <li class="white"><strong>Website:</strong> {{settings.websiteAddress}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                    <div class="footer-links">
                        <h3 class="white">Quick link</h3>
                        <ul>
                            <li><a href="about-us.html">About Us</a></li>
                            <li><a href="about-us.html">Delivery Information</a></li>
                            <li><a href="about-us.html">Privacy Policy</a></li>
                            <li><a href="about-us.html">Terms &amp; Conditions</a></li>
                            <li><a href="about-us.html">Customer Service</a></li>
                            <li><a href="#about-us.html">Return Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-12 mb-4">
                    <div class="footer-links">
                        <h3 class="white">Service</h3>
                        <ul>
                            <li><a href="tel:{{settings.phoneNo}}">Flight Tickets</a></li>
                            <li><a href="tel:{{settings.phoneNo}}">Hotels Booking</a></li>
                            <li><a href="tel:{{settings.phoneNo}}">Bus Tickets</a></li>
                            <li><a href="tel:{{settings.phoneNo}}">Train Ticket</a></li>
                            <li><a href="tel:{{settings.phoneNo}}">Cab Booking</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-payment">
        <div class="container">
            <div class="row footer-pay align-items-center justify-content-between text-lg-start text-center">
                <div class="col-lg-8 footer-payment-nav mb-4">
                    <ul class="">
                        <li class="me-2">We Support:</li>
                        <li class="me-2"><i class="fab fa-cc-mastercard fs-4"></i></li>
                        <li class="me-2"><i class="fab fa-cc-paypal fs-4"></i></li>
                        <li class="me-2"><i class="fab fa-cc-stripe fs-4"></i></li>
                        <li class="me-2"><i class="fab fa-cc-visa fs-4"></i></li>
                        <li class="me-2"><i class="fab fa-cc-discover fs-4"></i></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <div class="copyright-inner rounded p-3 d-md-flex align-items-center justify-content-between">
                <div class="copyright-text">
                    <p class="m-0 white">2022 {{settings.siteName}}. All rights reserved.</p>
                </div>
                <div class="social-links">
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="particles-js"></div>
</footer>


<div id="back-to-top">
    <a href="#"></a>
</div>