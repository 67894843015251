<div class="wrapper" id="booking">
    <mat-horizontal-stepper #stepper>
        <mat-step [stepControl]="firstFormGroup" [editable]="editable">



            <div class="form_wrapper">
                <div class="form_container">
                    <div class="title_container">
                        <h2>Book Your Round Trip Taxi</h2>
                    </div>
                    <div class="row clearfix">
                        <div class="">
                            <form [formGroup]="firstFormGroup">
                                <div class="row clearfix">
                                    <div class="col_half">
                                        <div>
                                            <label>Full Name</label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>person</mat-icon>
                                                </span>
                                                <input type="text" formControlName="name" placeholder="Full Name" />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col_half">
                                        <div>
                                            <label>Mobile Number</label>

                                            <div class="input_field"> <span>
                                                    <mat-icon>phone_in_talk</mat-icon>
                                                </span>
                                                <input type="number" formControlName="phone" placeholder="Mobile Number"
                                                    required />
                                            </div>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="submitted && contactValidation.name.errors">
                                        Name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submitted && contactValidation.phone?.errors?.required">
                                        Mobile number is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="submitted && contactValidation.phone?.errors?.pattern">
                                        Enter valid <strong> Mobile number </strong>
                                    </mat-error>
                                </div>

                                <div>
                                    <label>Pickup Address</label>
                                    <div class="input_field">
                                        <span>
                                            <mat-icon>location_on</mat-icon>
                                        </span>
                                        <input type="text" placeholder="Pickup Location" #origin />
                                    </div>
                                </div>

                                <div>
                                    <label>Drop Address</label>
                                    <div class="input_field"> <span>
                                            <mat-icon>location_on</mat-icon>
                                        </span>
                                        <input type="text" placeholder="Drop Location" #destination />
                                    </div>
                                </div>



                                <div>
                                    <label>Email(optional)</label>
                                    <div class="input_field"> <span>
                                            <mat-icon>email</mat-icon>
                                        </span>
                                        <input type="email" formControlName="email" placeholder="Email" />
                                        <mat-error *ngIf="submitted && contactValidation.email?.errors?.pattern">
                                            Enter valid <strong>Email ID</strong>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col_half">
                                        <div>
                                            <label>Pickup Date</label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>event</mat-icon>
                                                </span>
                                                <input type="text" formControlName="pickupDate"
                                                    placeholder="Pickup date" formControlName="pickupDate"
                                                    [min]="minDate" (click)="picker.open()" [matDatepicker]="picker"
                                                    readonly>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col_half">
                                        <div>
                                            <label>
                                                Pickup Time
                                            </label>
                                            <div class="input_field"> <span>
                                                    <mat-icon>
                                                        schedule
                                                    </mat-icon>
                                                </span>
                                                <input type="text" placeholder="Pickup time"
                                                    formControlName="pickupTime" [ngxTimepicker]="time" />
                                                <ngx-material-timepicker #time></ngx-material-timepicker>
                                                <mat-error
                                                    *ngIf="submitted && contactValidation.pickupTime?.errors?.required">
                                                    Pickup Time is <strong>required</strong>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="submitted && contactValidation.pickupDate?.errors?.required">
                                        Pickup Date is <strong>required</strong>
                                    </mat-error>
                                </div>
                                <div>
                                    <div>
                                        <label> Return Date</label>
                                        <div class="input_field">

                                            <span>
                                                <mat-icon>schedule</mat-icon>
                                            </span>
                                            <input type="text" [min]="contactValidation.pickupDate.value"
                                                (click)="picker1.open()" [matDatepicker]="picker1" matInput
                                                placeholder="Retun date" formControlName="returnDate" readonly>
                                            <mat-datepicker #picker1></mat-datepicker>
                                            <mat-error
                                                *ngIf="submitted && contactValidation.returnDate?.errors?.required">
                                                Enter valid <strong>return date</strong>
                                            </mat-error>
                                        </div>
                                    </div>

                                </div>
                                <label>Please select cab type</label>

                                <div class="carbox">
                                    <div *ngFor="let car of cars" class="col">


                                        <div class="cars-item" (click)="selectedCarType(car)">



                                            <label
                                                *ngIf="contactValidation.tripType.value === 'One Way' && car.carType !== 'Tempo-TT' || contactValidation.tripType.value === 'Round Trip' ">{{
                                                contactValidation.tripType.value !== 'Round Trip' ?
                                                car.oneway : car.roundtrip}} ₹
                                                / Km</label>

                                            <label
                                                *ngIf="contactValidation.tripType.value === 'One Way' && car.carType === 'Tempo-TT'">
                                                N/A</label>
                                            <img *ngIf="car.carType !== selectedCarName" src="{{car.image}}">
                                            <img *ngIf="car.carType === selectedCarName" src="{{car.activeImage}}">
                                            <label>{{car.carType}}</label>
                                        </div>


                                    </div>
                                </div>





                                <agm-map class="hide" [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                                    <ng-container *ngIf="showDirection">
                                        <agm-direction [origin]="pickupPlace" [destination]="dropPlace"
                                            (onResponse)="onResponses($event)">
                                        </agm-direction>
                                    </ng-container>
                                </agm-map>

                                <input class="button" type="submit" value="Get Estimation" (click)="requestAride()" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </mat-step>

        <mat-step>
            <div *ngIf="showOtpScreen" class="osahan-form">
                <div id="wrapper">
                    <div id="dialog">
                        <h3>Please enter the 4-digit verification code we sent via SMS:</h3>
                        <span>(we want to make sure it's you before we contact our drivers)</span>

                        <form (ngSubmit)="verifyOtp()" [formGroup]="form">
                            <div id="form">
                                <input *ngFor="let input of formInput;  index as i" type="number" #formRow
                                    formControlName="{{input}}" (keyup)="keyUpEvent($event, i)" maxLength="1" size="1"
                                    min="0" max="9" pattern="[0-9]{1}" />

                                <button class="btn btn-primary btn-embossed">Verify</button>
                            </div>
                            <p *ngIf="showOtpError" class="text-danger">Oops something went wrong. Try again sometime
                                later</p>
                            <p *ngIf="invalideOtp" class="text-danger">Enter Valid OTP</p>
                            <p *ngIf="successfullySentOtpMsg" class="text-warning">OTP sent successfully</p>
                            <p *ngIf="showTimer">Expect OTP in {{display}} </p>
                            <p *ngIf="!showTimer">Didn't receive it?<a (click)="sendOtp(true)"
                                    class="ml-2 text-danger">Resend</a>
                            </p>
                        </form>


                    </div>
                </div>
            </div>
        </mat-step>
        <mat-step>

            <div *ngIf="show" class="estimate-wrap">
                <p>Trip estimation for {{bookingDetails.originCityName}} to
                    {{bookingDetails.destinationCityName}}</p>
                <strong style="
                display: block;
                font-size: 38px;
                color: #fff;
                margin: 20px 0;
            ">
                    <span>Fare</span> {{bookingDetails.tripEstimation | currency:'INR' }}
                </strong>
                <b>Total Distance: </b>{{bookingDetails.distance}} KM <br>
                <b>Total Duration:</b> {{bookingDetails.totalDuration}} <br>
                <b>Selected Car:</b> {{bookingDetails.carType}} <br>
                <b>Driver Batta:</b> ₹{{bookingDetails.driverBatta}} <span *ngIf="days"> for {{this.days}} Days </span>
                <br>

                <em style="
                margin: 20px 0;
                display: inline-block;
            ">Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
                <div class="col-12 text-center mt-3 mb-3">
                    <button (click)="confirm()" mat-raised-button color="primary">Confirm Booking</button>
                </div>
            </div>

        </mat-step>
        <mat-step>
            <div class="success">
                <div style="text-align: center;" *ngIf="bookingDetails">
                    <h4>Ride Booked Successfully</h4>
                    <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250"
                        alt="" srcset="">
                    <p>
                        Thanks for Choosing {{settings.siteName}}, reservation details have been sent
                        to your email id & phone.
                    </p>
                    <div style="text-align: center;">
                        <button (click)="home()" mat-raised-button color="primary">Home</button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>