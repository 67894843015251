export const SITE_SETTINGS = {
    isCMS: true,
    isDatabaseEnable: false,
    isTelegramEnabled: false,
    isRentalFixedPrice: true,
    isSMSEnabled: false,
    OTP: false,
    siteName: 'Kovai Call Taxi',
    phoneNo: '9655665599',
    whatsapp: '+919500350435',
    websiteAddress: 'www.kovaicalltaxi.co.in',
    smsKey: 'HqgD48Sf3U26EsCNK5ldRXx9myWeVaQBconTOFwMiIt7r0uphJqFdDX2I4kij0tEhcTUgueNZnlMoJPm',
    contentAPI: "https://cdn.contentful.com/spaces/brr64dmu4x7k/environments/master/entries?access_token=ZGqVnk_hMQpvltuisuLov_WYlzskHeELc9GAW1XpPoc",
    enquiryEmail: 'taxisathuragiri@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '',
    tGroup: '',
    OTPApi: 'https://us-central1-smsapi-service.cloudfunctions.net/otp/api/v1/sendsms',
    logoURL:'https://www.kovaicalltaxi.co.in/assets/logo.png',
    address:'13/1, Amul Nagar Selvapuram Coimbatore 641026 , Tamil Nadu'
}