export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBoH24DZhfJj57eh5XV1AGEG0v7Gyhl1ZY",
    authDomain: "kovaicalltaxi-co-in.firebaseapp.com",
    databaseURL: "https://kovaicalltaxi-co-in-default-rtdb.firebaseio.com",
    projectId: "kovaicalltaxi-co-in",
    storageBucket: "kovaicalltaxi-co-in.appspot.com",
    messagingSenderId: "520500163795",
    appId: "1:520500163795:web:605a962f3beb30ce109026"
  }
};
