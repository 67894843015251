// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBoH24DZhfJj57eh5XV1AGEG0v7Gyhl1ZY",
    authDomain: "kovaicalltaxi-co-in.firebaseapp.com",
    databaseURL: "https://kovaicalltaxi-co-in-default-rtdb.firebaseio.com",
    projectId: "kovaicalltaxi-co-in",
    storageBucket: "kovaicalltaxi-co-in.appspot.com",
    messagingSenderId: "520500163795",
    appId: "1:520500163795:web:605a962f3beb30ce109026"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
