<div class="container text-center py-4 py-md-5 mt-4 mt-md-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
    <h1 class="display-3 mb-0">
        <img routerLink="/" style="
        width: 30px;
        margin-right: 15px;
    " src="assets/left-arrow.png" alt="" srcset=""> Contact us</h1>
    <p class="text-black-600 lh-lg big">Get in touch and a team of highly trained ninjas will get back to you</p>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-7">
            <div class="rounded-12 overflow-hidden mb-4 mb-md-0">
                <img src="assets/surprised.png" class="w-100" alt="Map">
            </div>
        </div>
        <div class="col-lg-5">
            <div class="pt-xl-5 pt-lg-0 pt-md-5 pb-lg-5 px-lg-5">
                <div class="mb-4">
                    <h6>Location</h6>
                    <p class="lh-lg">Chennai</p>
                </div>
                <div class="mb-4 pt-md-3">
                    <h6>Email</h6>
                    <p class="lh-lg"><a href="mailto:{{settings.enquiryEmail}}" class="text-secondary">{{settings.enquiryEmail}}</a></p>
                </div>
                <div class="mb-4 pt-md-3">
                    <h6 class="mb-3">Phone</h6>
                    <p class="mb-2"><a href="tel:{{settings.phoneNo}}" class="text-secondary">{{settings.phoneNo}}</a></p>
                </div>
                <div class="mb-4 pt-md-3">
                    <h6 class="mb-3">Whatsapp</h6>
                    <p class="mb-2"><a href="https://wa.me/{{settings.whatsapp}}" class="text-secondary">{{settings.whatsapp}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>