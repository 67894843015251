<section class="breadcrumb-main pb-20 pt-14" style="background-image: url(assets/images/bg/bg1.jpg);">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="breadcrumb-outer">
        <div class="container">
            <div class="breadcrumb-content text-center">
                <h1 class="mb-3">Tariff</h1>
                <nav aria-label="breadcrumb" class="d-block">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Tariff</li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="dot-overlay"></div>
</section>

<section class="about-us pt-6"
    style="background-image:url(images/background_pattern.png); background-position:bottom right;">
    <div class="container">
        <div class="about-image-box">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-lg-12 ps-4">
                    <div class="about-content text-center text-lg-start">

                        <section class="about type_one pt-5 pb-5">
                           
                            <div class="col-lg-6">
                                <div class="heading tp_one  icon_dark">

                                    <h1>One Way
                                    </h1>
                                </div>
                                <div class="about_content">
                                    <table style="width:100%" colspan="2">
                                        <tbody>
                                            <tr>
                                                <th>Vehicle Type</th>
                                                <th>Rate/KM</th>
                                                <th>Driver Bata</th>
                                                <th>Additional Charge</th>
                                            </tr>
                                            <tr *ngFor="let car of cars">
                                                <td>{{car.carType}}</td>
                                                <td>Rs.{{car.oneway}}/KM</td>
                                                <td>Rs.{{car.onewayDriverFee}}</td>
                                                <td>One Way Toll</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="heading tp_one  icon_dark mt-5">
                                    <h1>Round Trip
                                    </h1>
                                </div>
                                <div class="about_content">
                                    <table style="width:100%" colspan="2">
                                        <tbody>
                                            <tr>
                                                <th>Vehicle Type</th>
                                                <th>Rate/KM</th>
                                                <th>Driver Bata</th>
                                                <th>Additional Charge</th>
                                            </tr>
                                            <tr *ngFor="let car of cars">
                                                <td>{{car.carType}}</td>
                                                <td>Rs.{{car.roundtrip}}/KM</td>
                                                <td>Rs.{{car.roundTripDriverFee}}</td>
                                                <td>Two Way Toll</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </section>

                        <section class="about type_one bg-white pt-5 pb-5">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="heading tp_one  icon_dark">
                                            <h6>Terms and conditions</h6>
                                            <h1>Points before ur travel
                                            </h1>
                                        </div>
                                        <div class="about_content">
                                            <h4> Any Sedan</h4>
                                            <p class="description">

                                                Swift Dzire Toyota Etios Tata Zest Hyundai Xcent Nissan sunny anyone
                                                will be assigned

                                            </p>

                                            <h4>Any SUV
                                            </h4>

                                            <p class="description">Innova Xylo Ertiga any one will be assigned

                                            </p>

                                            <ul>
                                                <li>Maximum 4 passenger capacity for Sedan</li>
                                                <li>Maximum 7 passenger capacity for SUV</li>

                                                <li>Minimum 130km coverage for one way trip</li>
                                                <li> Minimum 250km coverage for round trip.</li>
                                                <li>One day Round trip close by 11pm at ur pickup </li>
                                                <li>Waiting charges Rs.150 per hour in between ur trip.</li>
                                                <li>Food break, tea break, & rest room no waiting chrgs.</li>
                                                <li>Cancellation charges Rs.300 after driver arrived.</li>
                                                <li>Luggage load in carrier Rs.300 if Mention in QT.</li>
                                                <li>Note down starting and ending km is must.</li>
                                                <li> Permit charges and Driver Bata is same as quoted.</li>
                                                <li>Toll charges will be paid thru fastag by Driver.</li>
                                                <li>
                                                    Payment  transfer by online or by cash.
                                                </li>
                                                <li>
                                                    EOT calculated running km, Toll by fastag & rest all.
                                                </li>
                                                <li> Toll fees, interstate Permit, Hill charges pet charges waiting
                                                    charges (if any) are
                                                    extra
                                                </li>
                                                <li>For Bangalore minimum 300km per day in Round trip
                                                </li>
                                                <li>1 day means 1 calendar day(from midnight 12 to next midnight 12)
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>