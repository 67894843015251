import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CARS } from 'src/app/cars';
import { PLACES, POPULAR_CITIES, popular_cities, popular_city } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';
declare var $;



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  // @ViewChild('stepper') stepper: MatStepper;

  title = 'mytaxi';
  // places = PLACES;
  cars;
  popularCities = popular_cities;
  settings = SITE_SETTINGS;
  cities = popular_city;
  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  origin;
  destination;
  showDirection = false;
  pickupPlace;
  dropPlace;
  totalDistance;
  totalDuration;
  requestRide = false;
  rideDetails;
  originCityName;
  destinationCityName;
  routeDetails;
  showSpinner = false;
  firstFormGroup: FormGroup;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  }
  submitted = false;
  places = POPULAR_CITIES;
  tariff;

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private router: Router, private taxiService: TaxiService, private _formBuilder: FormBuilder, private http: HttpClient,
  ) { }

  ngOnInit() {

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.tariff = res;
        this.cars = res;
      }
    })
  }

}
